
import './App.css';
import { BrowserRouter as Router, Route, Switch,  } from "react-router-dom"
import {
  isAndroid,
  isIOS
} from "react-device-detect";
import React, {useEffect} from 'react';
import DocumentMeta from 'react-document-meta';

function App() {

  useEffect(() => {
    if (isAndroid) {
      window.location.href =
        "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.clockingamesmobile&hl=en_US&gl=US";
    }else if(isIOS) {
      window.location.href =
        "https://apps.apple.com/us/app/clockingames/id1588868708";
    } else{
      window.location.href =
      "https://apps.apple.com/us/app/clockingames/id1588868708";
    }
  }, []);

  const meta = {
    title: "The #1 Place For Sport Game Discussions",
    description: "Start a game chat with your friends",
    canonical: '/image.jpg”',
    meta: {
      charset: 'utf-8',
     
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  };
  return (
    <DocumentMeta {...meta}>
      <img src='/image.png'/>
    </DocumentMeta>
  );
}

export default App;
